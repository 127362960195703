<template>
  <div class="map" id="container">
    <p class="title">乘客上车热力图</p>
    <!-- <el-select v-model="value" placeholder="请选择" class="select" @change="onChangeTime">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select>-->
  </div>
</template>

<script>
export default {
  name: 'headMap',
  data () {
    return {
      map: null,
      value: 'today',
      options: [
        {
          value: 'today',
          label: '今天'
        },
        {
          value: 'yesterday',
          label: '昨天'
        },
        {
          value: 'lastweek',
          label: '上周'
        },
        {
          value: 'lastmonth',
          label: '上个月'
        },
        {
          value: 'thisyear',
          label: '今年'
        }
      ],
      heatmapData: []
    }
  },
  methods: {
    initMap () {},
    isSupportCanvas () {
      // 判断浏览区是否支持canvas
      var elem = document.createElement('canvas')
      return !!(elem.getContext && elem.getContext('2d'))
    },
    onChangeTime (v) {
      console.log(v)
    }
  },
  created () {
    this.isSupportCanvas()
  },
  mounted () {
    this.initMap()
  }
}
</script>

<style lang="scss" scoped>
.map {
  height: 100%;
  width: 100%;
  position: relative;
  @include themify() {
    .title {
      position: absolute;
      top: 1.5vh;
      left: 1.5vh;
      font-size: (16rem/14);
      color: themed("n7");
      z-index: 999;
      margin: 0;
      user-select: none;
    }
    .select {
      position: absolute;
      top: 1.5vh;
      right: 1.5vh;
      z-index: 999;
      width: 7vw;
    }
  }
}
</style>
