<template>
  <div class="dashboard">
    <div class="header">
      <div class="left">
        <section>
          <h3>{{ timeSign }}，{{ realname }}！</h3>
          <p>欢迎使用由英海瑞特科技研发系统，祝您工作愉快~</p>
        </section>
      </div>
      <div class="right">
        <section>
          <h3>车辆总数</h3>
          <p>{{ revenue.vehicleTotal || 0 }}</p>
        </section>
        <section>
          <h3>在线车辆</h3>
          <p>{{ revenue.vehicleOnline || 0 }}</p>
        </section>
        <section>
          <h3>载客次数</h3>
          <p>{{ revenue.orderCount || 0 }}</p>
        </section>
        <section>
          <h3>营收（元）</h3>
          <p>{{ (revenue.revenueCount / 100).toFixed(2) }}</p>
        </section>
      </div>
    </div>
    <div class="main">
      <div class="item item1">
        <charts :option="item1Option" :id="item1"></charts>
      </div>
      <div class="item item2">
        <heat-map></heat-map>
      </div>
      <div class="item item3">
        <p class="title">报警处理情况</p>
        <div class="data">
          <div class="pie">
            <charts :option="item3Option" :id="item3"></charts>
          </div>
          <div class="information">
            <p class="rate-title">处理率</p>
            <p class="rate-num">
              <span>{{ (item3Value.disposeRatio * 100 || 0).toFixed(2) }}</span>
              <span>%</span>
            </p>
            <p class="solved">
              <span>已处理</span>
              <span>{{ item3Value.disposeCount || 0 }}</span>
            </p>
            <p class="unsolved">
              <span>未处理</span>
              <span>{{ item3Value.initCount || 0 }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="item item4">
        <p class="title">收入排行</p>
        <div class="rank-table">
          <el-table :data="item4Data" :show-header="false">
            <el-table-column label="排行" width="50">
              <template slot-scope="scope">
                <span :class="'rank' + scope.row.order">
                  {{ scope.row.order }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="cph" label="车牌号"></el-table-column>
            <!-- <el-table-column prop="driverName" label="司机姓名"></el-table-column> -->
            <el-table-column label="收入" width="150">
              <template slot-scope="scope">
                <span>{{ scope.row.operatingAmountCount | incomeFilter }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="item item5">
        <p class="title">报警排行</p>
        <div class="rank-table">
          <el-table :data="item5Data" :show-header="false">
            <el-table-column label="排行" width="50">
              <template slot-scope="scope">
                <span :class="'rank' + scope.row.order">
                  {{ scope.row.order }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="cph" label="车牌号"></el-table-column>
            <!-- <el-table-column prop="driverName" label="司机姓名"></el-table-column> -->
            <el-table-column prop="vehicleGroupCount" label="次数" width="150">
              <template slot-scope="scope">
                <span>{{ scope.row.vehicleGroupCount }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="item item6">
        <charts :option="item6Option" :id="item6"></charts>
      </div>
      <!-- <div class="item item7">
        <charts :option="item7Option" :id="item7"></charts>
      </div>-->
      <div class="item item8">
        <p class="title">报警类型排行</p>
        <div class="rank-table">
          <el-table :data="item8Data" :show-header="false">
            <el-table-column label="排行" width="50">
              <template slot-scope="scope">
                <span :class="'rank' + scope.row.order">
                  {{ scope.row.order }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="alarmName"
              label="报警类型"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              prop="alarmTypeGroupCount"
              label="报警次数"
              width="150"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.alarmTypeGroupCount }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import charts from '@/components/charts/charts.vue'
import heatMap from '@/components/map/heatMap/heatMap.vue'
import {
  queryRevenue,
  queryOperatingAmountTop,
  queryIncomeCount,
  queryOccupancyRate,
  queryVehicleNoGroupAlarm,
  queryAlarmTypeGroupAlarm,
  queryDisposeStatusGroupAlarm
} from '@/api/lib/refreshAlarm.js'

export default {
  components: {
    charts,
    heatMap
  },
  data() {
    return {
      realname: null,
      revenue: {},
      item1: 'item1',
      item1Option: {},
      item7: 'item7',
      item7Option: {},
      item6: 'item6',
      item6Option: {},
      item3: 'item3',
      item3Option: {},
      item3Value: {},
      item4Data: [],
      item5Data: [],
      item8Data: []
    }
  },
  methods: {
    /** *查询车辆总数、在线数、运客数、营收 */
    queryRevenue() {
      queryRevenue({}).then((res) => {
        this.revenue = res.data
      })
    },
    /** *平台收入情况统计 */
    queryIncomeCount() {
      const xAxis = []
      const yAxis = []
      queryIncomeCount({}).then((res) => {
        res.data.forEach((element) => {
          xAxis.push(`${element.key}:00`)
          yAxis.push(element.operatingAmountAll / 100)
        })
        this.item1Option = {
          title: {
            text: '收入情况',
            left: 0,
            textStyle: {
              color: '#666666',
              fontSize: 16
            },
            top: 0
          },
          grid: {
            top: 45,
            left: 40,
            right: 10,
            bottom: 20
          },
          tooltip: {
            show: true,
            trigger: 'axis',
            textStyle: {
              fontSize: 12
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#D9D9D9'
              }
            },
            axisTick: {
              lineStyle: {
                color: '#D9D9D9'
              }
            },
            axisLabel: {
              color: '#666666',
              fontSize: 12
            },
            splitLine: {
              show: false
            },
            data: xAxis
          },
          yAxis: {
            type: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#666666',
              fontSize: 12
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: '#E9E9E9'
              }
            }
          },
          series: [
            {
              data: yAxis,
              type: 'line',
              smooth: true,
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(5, 147, 112, 0.2)' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(5, 147, 112, 0)' // 100% 处的颜色
                    }
                  ],
                  global: false // 缺省为 false
                }
              },
              itemStyle: {
                normal: {
                  color: '#059370',
                  lineStyle: {
                    color: '#059370'
                  }
                }
              }
            }
          ]
        }
      })
    },
    /** *出租收入排行 */
    queryOperatingAmountTop() {
      queryOperatingAmountTop({}).then((res) => {
        this.item4Data = res.data
      })
    },

    /** 载客率统计 */
    queryOccupancyRate() {
      const xAxis = []
      const loadCount = []
      const emptyCount = []
      const occupancyRate = []
      queryOccupancyRate({}).then((res) => {
        res.data.forEach((element) => {
          xAxis.push(`${element.key}:00`)
          loadCount.push(element.loadCount)
          emptyCount.push(element.emptyCount)
          occupancyRate.push(element.occupancyRate.toFixed(2) * 100)
        })
        this.item6Option = {
          title: {
            text: '载客率统计',
            left: 0,
            textStyle: {
              color: '#666666',
              fontSize: 16
            },
            top: 0
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            },
            formatter: '{a0}: {c0}<br />{a1}: {c1}<br />{a2}: {c2}%'
          },
          grid: {
            top: 50,
            left: 40,
            right: '8%',
            bottom: 20
          },
          legend: {
            right: 0,
            itemWidth: 20,
            itemHeight: 10,
            data: ['重车', '空车', '载客率']
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: true,
              axisLine: {
                onZero: false,
                show: true,
                lineStyle: {
                  color: '#D9D9D9'
                }
              },
              axisTick: {
                lineStyle: {
                  color: '#D9D9D9'
                }
              },
              axisLabel: {
                color: '#666666',
                fontSize: 12
              },
              splitLine: {
                show: false
              },
              data: xAxis
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '车辆数量',
              position: 'left',
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                color: '#666666',
                fontSize: 12
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E9E9E9'
                }
              }
            },
            {
              type: 'value',
              min: 0,
              max: 100,
              position: 'right',
              axisLine: {
                lineStyle: {
                  color: '#059370'
                }
              },
              axisLabel: {
                formatter: '{value}%'
              }
            }
          ],
          series: [
            {
              name: '重车',
              data: loadCount,
              type: 'bar',
              barGap: '20%',
              barWidth: 10,
              itemStyle: {
                normal: {
                  color: '#059370',
                  lineStyle: {
                    color: '#059370'
                  }
                }
              }
            },
            {
              name: '空车',
              data: emptyCount,
              type: 'bar',
              barGap: '20%',
              barWidth: 10,
              itemStyle: {
                normal: {
                  color: '#FFC003',
                  lineStyle: {
                    color: '#FFC003'
                  }
                }
              }
            },
            {
              name: '载客率',
              data: occupancyRate,
              type: 'line',
              smooth: true,
              yAxisIndex: 1,
              itemStyle: {
                normal: {
                  color: '#059370',
                  lineStyle: {
                    color: '#059370'
                  }
                }
              }
            }
          ]
        }
      })
    },
    /** 车辆报警排行 */
    queryVehicleNoGroupAlarm() {
      queryVehicleNoGroupAlarm({}).then((res) => {
        this.item5Data = res.data
      })
    },
    /** 报警处理情况统计 */
    queryDisposeStatusGroupAlarm() {
      queryDisposeStatusGroupAlarm({}).then((res) => {
        this.item3Value = {
          ...res.data
        }
        this.item3Option = {
          title: {
            text: '总报警数',
            left: 'center',
            top: '45%',
            textStyle: {
              color: '#999999',
              fontSize: 14
            },
            subtext: res.data.allCount,
            subtextStyle: {
              fontSize: 16,
              color: '#333333',
              fontWeight: 'bold'
            }
          },
          color: ['#059370', '#FAD20C'],
          grid: {
            top: '3%',
            left: '3%',
            right: '3%',
            bottom: '3%'
          },
          tooltip: {
            show: false
          },
          series: [
            {
              name: '访问来源',
              type: 'pie',
              radius: ['60%', '75%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: false
                }
              },
              data: [
                { value: res.data.disposeCount || 0, name: '已处理' },
                { value: res.data.initCount || 0, name: '未处理' }
              ]
            }
          ]
        }
      })
    },
    /** 报警类型排行 */
    queryAlarmTypeGroupAlarm() {
      queryAlarmTypeGroupAlarm({}).then((res) => {
        this.item8Data = res.data
      })
    }
  },

  computed: {
    timeSign() {
      const timeSign = new Date().getHours()
      if (timeSign >= 6 && timeSign < 11) {
        return '早上好'
      } else if (timeSign >= 11 && timeSign < 13) {
        return '中午好'
      } else if (timeSign >= 13 && timeSign < 18) {
        return '下午好'
      } else {
        return '晚上好'
      }
    }
  },
  filters: {
    incomeFilter(v) {
      return `¥ ${(v / 100).toFixed(2)}`
    }
  },
  created() {
    this.realname = sessionStorage.getItem('realname')
    this.queryRevenue()
    this.queryOperatingAmountTop()
    this.queryIncomeCount()
    this.queryOccupancyRate()
    this.queryVehicleNoGroupAlarm()
    this.queryAlarmTypeGroupAlarm()
    this.queryDisposeStatusGroupAlarm()
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  height: 100%;
  // display: flex;
  // flex-direction: column;
  overflow: hidden;
  @include themify() {
    .header {
      height: 8vh;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      // margin-bottom: 20px;
      // align-items: center;
      .left {
        section {
          h3 {
            font-size: (32rem/14);
            font-weight: 400;
            color: themed('b');
            margin: 0 0 1vh 0;
          }
          p {
            font-size: (12rem/14);
            color: themed('n6');
            margin: 0;
          }
        }
      }
      .right {
        display: flex;
        flex-wrap: nowrap;
        section {
          margin-left: 5vw;
          text-align: left;
          h3 {
            font-size: 1rem;
            font-weight: normal;
            color: themed('n6');
            margin: 0 0 1vh 0;
          }
          p {
            font-size: (26rem/14);
            font-weight: 600;
            color: themed('n7');
            margin: 0;
          }
        }
      }
    }
    .main {
      // flex: 1;
      height: calc(92vh - 100px);
      display: grid;
      grid-auto-columns: 2fr 3fr 2fr;
      grid-template-rows: repeat(3, 1fr);
      // grid-template-rows: calc((92vh - 120px) / 3);
      grid-template-areas:
        'a b c'
        'd b e'
        'f b h';
      grid-gap: 20px 20px;
      .item {
        padding: 1.5vh;
        overflow: hidden;
        border-radius: 4px;
      }
      .item1 {
        grid-area: a;
      }
      .item2 {
        grid-area: b;
      }
      .item3 {
        grid-area: c;
        display: flex;
        flex-direction: column;
        .title {
          font-size: 16px;
          font-weight: 600;
          color: themed('n7');
          margin-top: 0;
          padding-left: 0.5vw;
        }
        .data {
          display: flex;
          flex-wrap: nowrap;
          flex: 1;
          align-items: stretch;
          .pie {
            flex: 3;
          }
          .information {
            flex: 2;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            p {
              margin: 0;
            }
            .rate-title {
              color: themed('n6');
              font-size: 1rem;
            }
            .rate-num {
              color: themed('n8');
              span {
                &:first-of-type {
                  font-size: (24rem/14);
                  font-weight: bold;
                }
                &:last-of-type {
                  font-size: (12rem/14);
                }
              }
            }
            .solved,
            .unsolved {
              font-size: (12rem/14);
              color: themed('n7');
              display: flex;
              align-items: center;
              flex-wrap: nowrap;
              &::before {
                display: inline-block;
                content: '';
                height: (10rem/14);
                width: (10rem/14);
                border: 2px solid;
                border-radius: 50%;
                margin-right: 0.5vw;
              }
              span {
                &:first-of-type {
                  margin-right: 2vw;
                }
              }
            }
            .solved {
              &::before {
                border-color: themed('b4');
              }
            }
            .unsolved {
              &::before {
                border-color: themed('y4');
              }
            }
          }
        }
      }
      .item4 {
        grid-area: d;
      }
      .item5 {
        grid-area: e;
      }
      .item6 {
        grid-area: f;
      }
      .item7 {
        grid-area: g;
      }
      .item8 {
        grid-area: h;
      }
      .item4,
      .item5,
      .item8 {
        display: flex;
        flex-direction: column;
        .title {
          font-size: 16px;
          font-weight: 600;
          color: themed('n7');
          margin: 0 0 1vh;
          padding-left: 0.5vw;
        }
        .rank-table {
          flex: 1;
        }
      }
    }
  }
}

span[class*='rank'] {
  display: inline-block;
  height: 2vh;
  width: 2vh;
  line-height: 2vh;
  text-align: center;
  border-radius: 50%;
}

.theme-project-taxi {
  .rank1 {
    background: #059370;
    color: #ffffff;
  }
  .rank2 {
    background: rgba($color: #059370, $alpha: 0.6);
    color: #ffffff;
  }
  .rank3 {
    background: rgba($color: #059370, $alpha: 0.4);
    color: #ffffff;
  }
  .rank4 {
    background: rgba($color: #f0f2f5, $alpha: 1);
    color: #666666;
  }
  .rank5 {
    background: rgba($color: #f0f2f5, $alpha: 1);
    color: #666666;
  }
  .item {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
